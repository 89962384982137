import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import ContactUS from './components/contactus';

function App() {
  return (
    <>
       <Header/>
       <ContactUS/>
    </>
  );
}

export default App;
